import React, { FC, memo } from 'react';
import { Grid, Row } from '../Grid';
import Button from '../Button';
import useTranslations from '@hooks/useTranslations';
import { SC } from '../../styles/theme';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { Icon, Icons } from '@components/Icon';
import { useRouter } from 'next/router';
import Link from '@components/Link';
import { useGTM } from '@hooks/useGTM';

export interface IMyAccountProps extends SC {
    brandIdRedirectRegistration: () => void;
    brandIdRedirectLogin: () => void;
    agentLoginRedirection?: () => void;
}

const IS_AGENT_LOGIN_ENABLED_HASH = 'b326b5062b2f0e69046810717534cb09';

const MyAccountTemplate: FC<IMyAccountProps> = memo(
    ({ className, brandIdRedirectRegistration, brandIdRedirectLogin, agentLoginRedirection }) => {
        const { t } = useTranslations();

        const { pushToDataLayer } = useGTM();

        const router = useRouter();

        const isAgentLoginEnabled =
            useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_AGENT_LOGIN_ENABLED) ||
            router.query.showLoginOptionAsDealer === IS_AGENT_LOGIN_ENABLED_HASH;

        return (
            <Grid noPaddingMobile className={className}>
                <Row className="buttonWrapper">
                    <Button primary data-testid="TESTING_REGISTER" onClick={brandIdRedirectRegistration}>
                        {t('login.button.register')}
                    </Button>
                </Row>
                <Row className="buttonWrapper">
                    <Button primary className="login" data-testid="TESTING_USER_LOGIN" onClick={brandIdRedirectLogin}>
                        {t('login.button.login')}
                    </Button>
                </Row>
                {isAgentLoginEnabled && agentLoginRedirection && (
                    <Row className="linkWrapper">
                        <Link
                            label={t('login.button.loginAgent')}
                            onClick={() => {
                                pushToDataLayer({
                                    event: 'uaevent',
                                    eventCategory: 'Content',
                                    eventAction: 'Redirection::Internal',
                                    eventLabel: t('login.button.loginAgent'),
                                });
                                agentLoginRedirection();
                            }}
                            primary
                            withArrowIcon
                            dataTestId="TESTING_AGENT_LOGIN"
                        />
                    </Row>
                )}
            </Grid>
        );
    }
);
MyAccountTemplate.displayName = 'MyAccountTemplate';

export default MyAccountTemplate;
