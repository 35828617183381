import { css } from '../../styles/theme';
import { rem } from 'polished';

export const StylesAC = css`
    .buttonWrapper > button {
        font-family: 'CitroenType';
        width: ${rem('300px')};
        background-color: ${({ theme }) => theme.colors.blue2};
        border: 1px solid ${({ theme }) => theme.colors.blue2};
        color: ${({ theme }) => theme.colors.white};

        &:hover {
            background-color: ${({ theme }) => theme.colors.grey5};
            border-color: ${({ theme }) => theme.colors.grey5};
            color: ${({ theme }) => theme.colors.white};
        }
    }
    .buttonWrapper > .login {
        background-color: unset;
        border: 2px solid ${({ theme }) => theme.colors.blue2};
        color: ${({ theme }) => theme.colors.blue2};
    }
`;
