import { css, breakpoints } from '../../styles/theme';
import { rem } from 'polished';

export const StylesDS = css`
    display: flex;
    flex-direction: column;

    .buttonWrapper > button {
        border-radius: ${rem('24px')};
        padding: ${rem('15px')};
        height: ${rem(48)};
        background-color: ${({ theme }) => theme.colors.black1};
        transition: all 0.3s ease;

        &:hover {
            background-color: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};
        }

        &.login {
            background-color: unset;
            border: solid ${rem('1px')} ${({ theme }) => theme.colors.black1};
            color: ${({ theme }) => theme.colors.black1};

            &:hover {
                background: ${({ theme }) => theme.colors.grey4};
                border-color: ${({ theme }) => theme.colors.primary};
            }
        }
    }
`;
