import { css } from 'src/styles/theme';
import { rem } from 'polished';

export const StylesOV = css`
    .buttonWrapper {

        button {
            width: ${rem('300px')};
            color: ${({ theme }) => theme.colors.black2};
            background-color: ${({ theme }) => theme.colors.primary};

            :hover {
                color: ${({ theme }) => theme.colors.pureWhite};
                background-color: ${({ theme }) => theme.colors.black2};
            }
        }
    }
`;
