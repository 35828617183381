import React from 'react';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV, isMarketGB } from '../../constants/main';
import styled from 'styled-components';
import MyAccountTemplate from './MyAccountTemplate';
import { rem } from 'polished';
import { StylesAC } from './Styles.AC';
import { StylesAP } from './Styles.AP';
import { StylesDS } from './Styles.DS';
import { StylesOV } from './Styles.OV';
import { StylesOVGB } from './Styles.OVGB';

export const MyAccount = styled(MyAccountTemplate)`
    display: flex;
    flex: auto;
    flex-flow: column;

    p {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
    }

    .buttonWrapper {
        display: flex;
        justify-content: center;
        margin: ${rem('15px')};

        button {
            width: ${rem('300px')};
        }
    }

    .linkWrapper {
        display: flex;
        justify-content: center;
        margin: ${rem('15px')};
    }

    ${isBrandAC && StylesAC};
    ${isBrandAP && StylesAP};
    ${isBrandDS && StylesDS};
    ${isBrandOV && (isMarketGB ? StylesOVGB : StylesOV)};
`;

export default MyAccount;
