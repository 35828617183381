import React, { useEffect } from 'react';
import Main from '@components/Layout/Main';
import LoginBrandId from '@components/MyAccount';
import { NextPage } from 'next';
import { ContentDuck } from '../redux/content/content.duck';
import { connect, useSelector } from 'react-redux';
import userDuck from '../redux/user/user.duck';
import {
    BRANDID_CLIENT_ID,
    BRANDID_LOGIN_URL,
    BRANDID_MY_ACCOUNT_URL,
    BRANDID_REDIRECT_BOUNCE_URL,
    BRANDID_REGISTER_URL,
    BRANDID_RETURN_URL,
} from '../constants/myAccount';
import { B2B_BRANDID_REGISTER_URL_SUFFIX, IS_B2B } from '../constants/main';
import LoginSaveCar from '@components/LoginSaveCar';
import LoaderOverlay from '@components/LoaderOverlay';
import { brandIdRedirect } from '@utils/url.utils';
import { stepCodes, useUpdateStepCode } from '@hooks/useUpdateStepCode';
import { useGTM } from '@hooks/useGTM';
import { FilterDuck } from '../redux/filters/filter.duck';
import { EUserLoginType } from '../interfaces/User';
import { updateGeneralContext } from '@utils/getInitialProps/common';
import { ContextWithStore } from '@utils/getInitialProps/@types';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { IFooterItem, IHeaderItem } from 'src/redux/content/content.duck.interface';

type IComponentProps = {
    footerLinks?: IFooterItem[];
    headerLinks?: IHeaderItem[];
    isLoggedIn?: boolean;
    loginType?: EUserLoginType;
    redirectTo?: string;
    redirectBounce?: string;
    saveDeal?: boolean;
};

export const Login: NextPage<IComponentProps> = ({
    loginType,
    isLoggedIn,
    redirectTo = BRANDID_MY_ACCOUNT_URL,
    redirectBounce = BRANDID_REDIRECT_BOUNCE_URL,
    saveDeal,
}) => {
    const { pushConnectionPagePageLoad } = useGTM();
    const isGigyaEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_GIGYA_ENABLED);
    useUpdateStepCode(undefined, stepCodes.login);
    const paymentJourney = useSelector((state) => FilterDuck.getPaymentJourneyType(state));

    // when logged in using Brand ID, then just go to url
    useEffect(() => {
        if (isLoggedIn && loginType !== EUserLoginType.GUEST) {
            window.location.href = redirectTo;
        }
    }, [isLoggedIn]);

    const agentLoginRedirection = () => {
        window.location.href = `${process.env.NEXT_PUBLIC_API_URL}/ssm/saml/login`;
    };

    const brandIdRedirectRegistration = () => {
        const brandIdStateJson: string = JSON.stringify({
            redirectTo,
        });
        let url = BRANDID_REGISTER_URL.replace('{redirectURL}', BRANDID_RETURN_URL);
        if (IS_B2B) {
            url = `${url}&creation=${B2B_BRANDID_REGISTER_URL_SUFFIX}`;
        }
        brandIdRedirect(url, brandIdStateJson, {
            isLogin: false,
        });
    };

    useEffect(() => {
        pushConnectionPagePageLoad(paymentJourney);
    }, []);

    const brandIdRedirectLogin = () => {
        const brandIdState: any = {
            redirectTo,
        };

        // Add redirect bounce to state if its defined
        if (redirectBounce) {
            brandIdState.redirectBounce = redirectBounce;
        }

        const brandIdStateJson: string = JSON.stringify(brandIdState);

        const url = BRANDID_LOGIN_URL.replace('{clientId}', BRANDID_CLIENT_ID).replace(
            '{redirectURL}',
            BRANDID_RETURN_URL
        );
        brandIdRedirect(url, brandIdStateJson, {
            isLogin: true,
            isGigyaEnabled,
        });
    };

    const loginCanonicalLink = '/login';

    return (
        <Main title="Login" canonicalLink={loginCanonicalLink}>
            {saveDeal ? <LoginSaveCar /> : null}
            {!isLoggedIn || loginType === EUserLoginType.GUEST ? (
                <LoginBrandId
                    brandIdRedirectRegistration={brandIdRedirectRegistration}
                    brandIdRedirectLogin={brandIdRedirectLogin}
                    agentLoginRedirection={agentLoginRedirection}
                />
            ) : (
                <LoaderOverlay />
            )}
        </Main>
    );
};
Login.getInitialProps = async (context: ContextWithStore) => {
    const {
        query: { redirectTo, saveDeal },
    } = await updateGeneralContext(context);
    return {
        redirectTo: Array.isArray(redirectTo) ? redirectTo[0] : redirectTo,
        saveDeal: !!saveDeal,
    };
};

const mapStateToProps: (state: any) => IComponentProps = (state) => ({
    footerLinks: ContentDuck.getFooter(state),
    headerLinks: ContentDuck.getHeader(state),
    isLoggedIn: userDuck.getOwnState(state).userAuth.isLoggedIn,
    loginType: userDuck.getOwnState(state).userAuth.loginType,
});

export default connect(mapStateToProps)(Login);
