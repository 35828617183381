import styled from 'styled-components';
import { LoginSaveCarStyled } from '../LoginSaveCarStyled';
import { rem } from 'polished';

export const LoginSaveCarStyledAC = styled(LoginSaveCarStyled)`
    color: ${({ theme }) => theme.colors.grey1};
    font-family: ${({ theme }) => theme.fonts.fontBase};
    font-size: ${rem('12px')};
    text-align: center;
`;
