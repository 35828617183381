import React from 'react';
import { BRAND } from '../../constants/main';
import { LoginSaveCarStyled } from './LoginSaveCarStyled';
import { LoginSaveCarStyledAC } from './AC/PriceLegalTextStyled.AC';
import { LoginSaveCarStyledOV } from './OV/PriceLegalTextStyled.OV';
import { LoginSaveCarStyledAP } from './AP/LoginSaveCarStyled.AP';
import { LoginSaveCarStyledDS } from './DS/LoginSaveCarStyled.DS';

const LoginSaveCar = (props: any) => {
    if (BRAND === 'AC') return <LoginSaveCarStyledAC {...props} />;
    if (BRAND === 'OV') return <LoginSaveCarStyledOV {...props} />;
    if (BRAND === 'AP') return <LoginSaveCarStyledAP {...props} />;
    if (BRAND === 'DS') return <LoginSaveCarStyledDS {...props} />;
    return <LoginSaveCarStyled {...props} />;
};

export default LoginSaveCar;
